<template>
  <div class="detailbg">
    <div style="padding-bottom: 80px">
      <div>
        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img src="../../assets/hua1.jpg" alt="" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../assets/hua2.jpg" alt="" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../assets/hua3.jpg" alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div>
        <div>
          <div style="margin: 10px 15px; font-size: 18px">
            河南品讯网络科技有限公司
          </div>
          <div class="detailsB">
            <span>互联网IT</span>
            <span style="margin: 0px 15px">|</span>
            <span style="margin-left: 4px">互联网科技公司</span>
          </div>
        </div>
        <div>
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 10px;
            "
          >
            公司简介
          </div>
          <div style="margin: 5px 15px; font-size: 14px">
            “在洛阳”以城市品牌服务商为愿景，以“诚信、专业，成就客户；走心、成长，精铸品牌；奉献、合作，创造价值”为企业核心价值观；坚持“以客户为中心，以奋斗者为本”的发展理念，打造符合城市发展与品牌价值的综合服务新业态，让“有故事，有温度，品质生活在洛阳”的理念深入人心。
            “在洛阳”现有员工近百人，深耕文化传媒、公关策划、互联网科技、电商等领域，拥有一支集商务、运营、视频、企划和技术于一体的专业团队，业务范围涵盖全媒体运营、影视制作、品牌包装、宣传营销、全案策划、活动执行、会务会展、大数据综合服务、技术开发、电商平台等板块，为客户提供个性化、定制化、信息化的一站式服务。
          </div>
        </div>
        <div>
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司规模
          </div>
          <div style="margin: 5px 15px; font-size: 14px">
            <div>
              <span style="color: #999999">注册资本</span>
              <span style="padding-left: 15px">100万</span>
            </div>
            <div>
              <span style="color: #999999">成立日期</span>
              <span style="padding-left: 15px">2018-03-01</span>
            </div>
            <div>
              <span style="color: #999999">公司规模</span>
              <span style="padding-left: 15px">60人</span>
            </div>
          </div>
        </div>
        <div>
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司联系方式
          </div>
          <div style="margin: 10px 15px; font-size: 15px">
            <div>
              <span style="color: #999999">负责人</span>
              <span style="padding-left: 15px">陈东旭</span>
            </div>
            <div style="margin-top: 6px">
              <span style="color: #999999">联系电话</span>
              <span style="padding-left: 15px">18638870507</span>
            </div>
            <div style="margin-top: 6px">
              <span style="color: #999999">固定电话</span>
              <span style="padding-left: 15px">0379-68881111</span>
            </div>
            <div style="margin-top: 6px">
              <span style="color: #999999">企业邮箱</span>
              <span style="padding-left: 15px">zly@hnpinxun.cn</span>
            </div>
            <div style="margin-top: 6px">
              <span style="color: #999999">企业官网</span>
              <span style="padding-left: 15px">http://www.hnpinxun.cn/</span>
            </div>
          </div>
        </div>
        <div>
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司成功项目
          </div>
          <div style="margin: 10px 15px; font-size: 15px">
            <div>
              <span style="color: #999999">智慧社区管理平台</span>
              <div style="font-size: 14px; margin: 5px 0px">
                智慧社区是指通过利用各种智能技术和方式，整合社区现有的各类服务资源，为社区群众提供政务、商务、娱乐、教育、医护及生活互助等多种便捷服务的模式。从应用方向来看，“智慧社区”应实现“以智慧政务提高办事效率，以智慧民生改善人民生活，以智慧家庭打造智能生活，以智慧小区提升社区品质”的目标。
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司地址
          </div>
          <div style="margin: 10px 15px; position: relative">
            <div>
              <span style="font-size: 14px">河南省洛阳市洛龙区</span>
              <br />
              <span style="font-size: 13px; color: #a3a3a3"
                >开元大道与长夏门街创智广场3栋501</span
              >
            </div>
            <div class="detailsC">
              <span style="color: #ff5050; font-size: 22px; margin-right: 15px"
                ><van-icon name="location"
              /></span>
              <span style="color: #ff5050; font-size: 22px; margin-left: 15px"
                ><van-icon name="phone"
              /></span>
            </div>
          </div>
        </div>
        <div>
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            评价(1)
          </div>
          <div style="margin: 10px 15px; font-size: 15px">
            <div>
              <span style="color: #999999">匿名用户:</span>
              <div style="font-size: 14px; margin: 5px 0px">
                有幸合作过贵公司,服务相对不错,对于项目的后续服务质量很棒!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detailsD">
      <div class="detailsDs">
        <span>服务清单</span>
        <button @click="popupshow = true">立即查看</button>
      </div>
    </div>
      <van-popup
        v-model="popupshow"
        round
        position="bottom"
        :style="{ height: '80%' }"
      >
        <div class="fuwu_box">
          <div style="padding-top:10px">企业小程序开发</div>
          <div style="font-size:14px">为企业量身定做小程序,方便企业管理。</div>
          <div style="font-size:14px;color:red">¥5000~7000元</div>
        </div>
        <div class="fuwu_box">
          <div style="padding-top:10px">企业公众号开发</div>
          <div style="font-size:14px">企业公众号，帮助互联网时代的企业，实现微信平台的管理、互动和粉丝增长，帮助商家便捷的搭建小程序体系，帮助商家布局移动互联网，让商家的生意做的更好。</div>
          <div style="font-size:14px;color:red">¥5000~7000元</div>
        </div>
        <div class="fuwu_box">
          <div style="padding-top:10px">企业官方网站开发</div>
          <div style="font-size:14px">利用企业官网树立企业品牌形象对于每个企业来说都是非常重要的。良好的品牌形象是企业立足于市场的有利武器，能让用户很轻松的记住企业的产品或服务，并形成一种替意识的认同感。</div>
          <div style="font-size:14px;color:red">¥5000~7000元</div>
        </div>
      </van-popup>
    </div>
</template>
<script>
export default {
  data() {
    return {
      popupshow: false,
    };
  },
  methods: {
    
  },
};
</script>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.detailsA {
  position: relative;
  margin: 10px 15px;
}
.detailsA span:nth-child(1) {
  color: #fb4e44;
  font-size: 24px;
}
.detailsA span:nth-child(2) {
  padding-left: 15px;
  font-size: 14px;
  color: #ccc;
}
.detailsA span:nth-child(3) {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 14px;
}
.detailsB {
  display: flex;
  align-items: center;
  margin: 5px 15px;
  color: #666666;
  font-size: 14px;
}
.detailbg {
  background: #fff;
}
.detailsC {
  position: absolute;
  right: 25px;
  top: 7px;
}
.detailsD {
  position: fixed;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background: #fafafa;
  border-top: 1px solid #ccc;
}
.detailsDs span {
  line-height: 50px;
  color: #fb4e44;
  margin: 0px 15px;
}
.detailsDs button {
  position: absolute;
  right: 0px;
  width: 120px;
  line-height: 50px;
  color: #fff;
  background: #fb4e44;
  border: none;
}
.fuwu_box {
  width: 90%;
  margin: 10px 5%;
  background: #fafafa;
  border-radius: 5px;
}
.fuwu_box div{
  padding: 0px 10px 8px 15px;
}
</style>